import React, { useEffect, useState } from "react";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../../component/molecules/goBack";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/error-handler";
import { nullCheck } from "../../../util/check";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import PopupsApi from "../../../api/popupApi";
import { deleteFile, fileUpload } from "../../../util/file-management";

function PopupEdit(props) {
  let { id } = useParams();
  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = (await PopupsApi.GetById(id)).data.data;

      setTitle(response.title);
      setLink(response.link);
      setFileUrl(response.fileUrl);
      setFile(response.file);
    } catch (error) {
      errorHandler(error);
    }
  };

  const saveFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("link", link);
      if (file) {
        formData.append("file", file);
      }

      await PopupsApi.Update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>팝업명*</DetailFormTitle>
                <MainInput type={"text"} placeholder={"팝업명을 입력해주세요"} value={title} onChange={(e) => setTitle(e.target.value)} />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시링크</DetailFormTitle>
                <MainInput type={"text"} placeholder={"https://"} value={link} onChange={(e) => setLink(e.target.value)} />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지*</DetailFormTitle>
                {fileUrl ? (
                  <ImgPreview
                    fileUrl={fileUrl}
                    onClick={() => {
                      setFile("");
                      setFileUrl("");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"PopupImg"}
                    accept={"image/*"}
                    onChange={(e) => {
                      fileUpload(e, setFileUrl, setFile);
                    }}
                  />
                )}
              </DetailPageForm>
            </div>
            <SaveBtn
              text={"수정하기"}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (nullCheck(title) || nullCheck(fileUrl)) {
                  return toast("모든 내용을 입력해주세요.");
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? <Modal title={modalMsg.update?.title} text={modalMsg.update?.content} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : ""}
    </>
  );
}

export default PopupEdit;
