import api, { _axios } from "./api.js";

export default class CommonApi {
  static async FileAdd(data) {
    return await api.fileForm(`common/file`, data, "post");
  }

  static async SendMail(data) {
    return await api.post(`send/email`, data);
  }

  static async RequestNumber(data) {
    return await api.post(`common/check/phoneNumber`, data);
  }

  static async VerificationNumber(data) {
    return await api.post(`common/manager/check/phone`, data);
  }

  static async FileDownload(data) {
    return await _axios.post(`common/file/download`, data, {
      responseType: "blob",
    });
  }
}
